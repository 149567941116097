import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSelector,
  getAttributesForFilter,
  getCategoriesForFilter,
  onFilterParamsUpdate,
} from '../../store/feature/filter/filterSlice';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import { getMainPath } from '../../utils/getMainPath';
import { General_text } from '../../constant/Translation/General';
import { Filter_text } from '../../constant/Translation/Filter';
import { replaceSpecificStyles } from '../../utils/replaceSpacificStyles';

const AttributesButtonSelect = ({ filer_item, styles }) => {
  const {
    filter_type,
    title,
    translated_data,
    attribute_id,
    is_show_title,
    is_connected_to_category,
    is_depend_on_search,
  } = filer_item;
  const router = useRouter();
  const dispatch = useDispatch();
  const { attributes, filter_params, queries } = useSelector(filterSelector);

  // set attribute params when category_ids change and inside filter_params have category_ids
  const [attribute_params, setAttributeParams] = useState({});
  useEffect(() => {
    if (router?.query?.category_id && is_connected_to_category) {
      setAttributeParams({
        category_id: router?.query?.category_id,
      });
    }
  }, [router?.query?.category_id, is_connected_to_category]);

  useEffect(() => {
    dispatch(getAttributesForFilter(attribute_params));
  }, [attribute_params]);

  const filter_attribute = attributes?.find((item) => item.id == attribute_id);
  const values_ids = filter_attribute?.values?.map((vl) => vl.id) ?? [];

  //

  const onUpdateQuires = (updated_quires) => {
    if (is_depend_on_search !== true) {
      if (Object.keys(updated_quires).length === 0) {
        router.push({
          pathname: getMainPath(router.asPath),
          query: { page: router.query?.page ? router.query?.page : 1 },
        });
      } else {
        router.push({
          pathname: getMainPath(router.asPath),
          query: updated_quires ?? queries,
        });
      }
    }
  };

  //
  const filter_by_attribute_value = (value) => {
    const current_value = filter_params?.[`attribute_value_ids`] ?? [];
    const check = current_value?.find((ct) => values_ids.includes(ct));

    if (value === undefined) {
      const filter = current_value?.filter((ct) => ct != check);
      dispatch(
        onFilterParamsUpdate({
          [`attribute_value_ids`]: filter,
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    } else if (!current_value.includes(value) && !check) {
      dispatch(
        onFilterParamsUpdate({
          [`attribute_value_ids`]: [...current_value, value],
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    } else if (!current_value.includes(value) && check) {
      const filter = current_value?.filter((ct) => ct != check);
      dispatch(
        onFilterParamsUpdate({
          [`attribute_value_ids`]: [...filter, value],
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    }
  };

  return (
    filter_attribute?.values?.length > 0 && (
      <div
        style={{
          display: styles?.filter_component_style?.display,
          ...get_dynamic_styles(styles?.filter_component_style),
        }}
      >
        {title && is_show_title && (
          <p style={get_dynamic_styles(styles?.filter_title_style)}>
            {get_data_by_current_language({
              data_list: translated_data,
              key_name: 'title',
              default_data: title,
            })}
          </p>
        )}

        {/* All Button */}
        {filter_attribute?.values?.length > 0 && (
          <button
            className="flex border"
            style={get_dynamic_styles(
              !filter_params?.[`attribute_value_ids`]?.find((ct) =>
                values_ids.includes(ct)
              )
                ? styles?.filter_single_select_button_style
                : replaceSpecificStyles({
                    styles: styles?.filter_single_select_button_style,
                    remove_keys: {
                      background: 'transparent',
                      text_color:
                        styles?.filter_single_select_button_style?.background,
                    },
                    initial_values: {
                      border_color:
                        styles?.filter_single_select_button_style?.background,
                    },
                  })
            )}
            key={Math.random()}
            onClick={() => filter_by_attribute_value(undefined)}
          >
            {Filter_text.filter.all}
          </button>
        )}

        {filter_attribute?.values?.length > 0 &&
          filter_attribute?.values?.map((value) => {
            return (
              <button
                className="flex border"
                style={get_dynamic_styles(
                  filter_params?.[`attribute_value_ids`]?.includes(value?.id)
                    ? styles?.filter_single_select_button_style
                    : replaceSpecificStyles({
                        styles: styles?.filter_single_select_button_style,
                        remove_keys: {
                          background: 'transparent',
                          text_color:
                            styles?.filter_single_select_button_style
                              ?.background,
                        },
                        initial_values: {
                          border_color:
                            styles?.filter_single_select_button_style
                              ?.background,
                        },
                      })
                )}
                key={Math.random()}
                onClick={() => filter_by_attribute_value(value?.id)}
              >
                {value?.name}
              </button>
            );
          })}
      </div>
    )
  );
};

export default AttributesButtonSelect;
