import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import { getDatesArray2 } from '../Shared/Constant/Constant';
import { useRouter } from 'next/router';

const FiltersByDates = ({ data, styles }) => {
  const router = useRouter();
  const start_at = router?.query?.start_at;
  const end_at = router?.query?.end_at;
  const Filter_text = router?.query?.filter_text;

  let dates = getDatesArray2();

  // filter by date
  const filter_by_date = (sdate, edate) => {
    if (sdate == start_at) {
      const queries = { ...router.query };
      delete queries.start_at;
      delete queries.end_at;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          start_at: sdate,
          end_at: edate,
        },
      });
    }
  };

  return (
    <Swiper
      slidesPerView="auto"
      slidesPerGroup={1}
      spaceBetween={16}
      loop={false}
      navigation={false}
    >
      {dates?.map((date, i) => {
        return (
          <SwiperSlide
            className=""
            key={date}
            style={{
              width: '70px',
              minHeight: '30px',
            }}
          >
            {i == 0 ? (
              <button
                className={[
                  ' w-full h-[30px] flex items-center justify-center cursor-pointer   border border-primary rounded-button transition duration-700 hover:bg-primary hover:text-white mr-1  text-sm',
                  start_at == undefined && 'bg-primary text-white',
                ].join(' ')}
                id={date}
                onClick={() => {
                  const queries = { ...router.query };
                  delete queries.start_at;
                  delete queries.end_at;
                  router.push({
                    query: { ...queries },
                  });
                }}
              >
                {Filter_text.filter.all}
              </button>
            ) : (
              <button
                className={[
                  ' w-full h-[30px] flex items-center justify-center cursor-pointer   border border-primary rounded-button transition duration-700 hover:bg-primary hover:text-white mr-1  text-sm',
                  start_at?.split('T')[0] ==
                    `${moment(date).format('YYYY-MM-DD')}` &&
                    'bg-primary text-white',
                ].join(' ')}
                id={date}
                onClick={() => {
                  filter_by_date(
                    `${moment(date).format('YYYY-MM-DD')}T${changed_value[0]}Z`,
                    `${moment(date).add(1, 'days').format('YYYY-MM-DD')}T${
                      changed_value[1]
                    }Z`
                  );
                }}
              >
                {i == 1
                  ? Filter_text.filter.today
                  : moment(date).format('ddd D')}
              </button>
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default FiltersByDates;
