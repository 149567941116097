export var Checkout_text = {
  cart: {
    coupon_value: 'Coupon value',
    listing_tax: '+ {value}',
    coupon_applied: 'Great! {value} discount applied to your cart.',

    guest_information: 'Checkout as a Guest',
    guest_checkout_subtitle: 'Where should we e-mail your receipt',
    email: 'email',
    cart_sign_in_sign_up: 'Sign In or Sign Up',
    cart_sign_in_sign_up_subtitle: 'Sign In  for order faster and tracking',
    pay_with: 'Pay with',
    available_balance: 'Available Balance',
    shipment_preference: 'Shipment Preferences',

    apply_commission: 'Apply',
    cart_commission_minimum: 'Minimum amount is {value}',
    cart_commission_maximum: 'Maximum amount is {value}',

    apply_coupon: 'Apply',
    remove_coupon: 'Remove',
    shipment_header_title: 'Shipment',
    your_basket: 'Your basket',
    shipping_charge: 'Shipping Charge',
    items_fullfilled_by_platform: 'Items fulfilled by platform',
    items_fullfilled_by_account: 'Items fulfilled by {value}',

    address: 'address',
    go_to_order: 'Go to orders',
    thank_note: 'Thank you. Your Order is successful',
    order_summery: 'Order Summary',
    offer_percent: 'Offer Percent',
    by: 'By',
    in_stock: '{value} product in stock',
    quantity_limit: 'Quantity cannot be less than 1',
    grand_total: 'Grand Total',

    add_new_address: 'Add new address',
    alert_message_min_balance_checkout:
      'Your total cart amount should be more than {value} to proceed wit this order.',
    alert_stock_available: 'Stocks not available for selected Quantity',
    apply: 'Apply',
    cancel: 'Cancel',
    cash_on_delivery: 'Cash on delivery',
    clearing_cart: 'Clearing Cart',
    could_not_delete_cart_item:
      "Something went wrong. Couldn't able to remove cart item at the moment.Try again later",
    deliver_to: 'Deliver To',
    delivery_charge: 'Delivery Charge',
    discount: 'Discount',
    done: 'Done',
    enter_quantity: 'Enter Quantity',
    free: 'Free',
    header_title: 'My Cart',
    invalid_quantity: 'Invalid quantity',
    item_added_cart: 'Item added to cart',
    item_removed_cart: 'Item removed from cart',
    max_quantity: 'Max Quantity',
    more: 'More',
    qty: 'Qunatity',
    quantity: 'Qty',
    quantity_required: 'Required',
    quantity_updated_success: 'Quantity updated successfully',
    refreshing: 'Refreshing',
    remove: 'Remove',
    removed_success: 'Removed successfully',
    removing: 'Removing',
    select_pickup_address: 'Select Pickup Address',
    select_shipping_address: 'Please select shipping address to checkout',
    shipment: 'Shipping',
    shipping: 'Shipping',
    soldby: 'Sold by',
    some_items_removed: 'Some of your items may be removed',
    subtotal: 'Subtotal',
    tax: 'Tax',
    total: 'Total',
    updating_quantity: 'Updating quantity',
    you_pay: 'YOU PAY',
    your_cart_is_empty: 'Your cart is empty',
  },
};
