import React, { useEffect, useState } from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import AccountCategoryFilter from './AccountCategoryFilter';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import SearchButton from './SearchButton';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountFilterSelector,
  syncParamsWithQueries,
} from '../../store/feature/filter/accountFilterSlice';
import { filterSelector } from '../../store/feature/filter/filterSlice';
import AccountCategoryButtonFilter from './AccountCategoryButtonFilter';
import AttributesRadioSelect from './AttributesRadioSelect';
import AttributesButtonSelect from './AttributesButtonSelect';
import AttributeSingleSelectFilter from './AttributeSingleSelectFilter';

const AccountCustomFilterLayout = ({ styles, data, extra_data }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { filter_params, queries } = useSelector(accountFilterSelector);
  useEffect(() => {
    if (Object.keys(queries).length === 0 && filter_params === null) {
      dispatch(syncParamsWithQueries(router.query));
    }
  }, []);

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          gridTemplateColumns: dynamic_text_convert({
            main_text: styles?.box_styles?.columns_box_percentage,
            check_by: '-1%',
            value: '',
          }),
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.filter_items?.map((filer_item, index) => {
          const { filter_type, title, translated_data, attribute_id } =
            filer_item;
          switch (filter_type) {
            case 'category':
              return (
                <AccountCategoryFilter
                  filer_item={filer_item}
                  styles={styles}
                  key={`${filter_type}_${index}`}
                />
              );
            case 'category-button':
              return (
                <AccountCategoryButtonFilter
                  filer_item={filer_item}
                  styles={styles}
                  key={`${filter_type}_${index}`}
                />
              );

            case 'search-button':
              return (
                <SearchButton
                  filer_item={filer_item}
                  styles={styles}
                  key={`${filter_type}_${index}`}
                />
              );

            case 'single-select-radio-attribute':
              return (
                <AttributesRadioSelect
                  filer_item={filer_item}
                  styles={styles}
                  key={`${filter_type}_${index}`}
                />
              );
            case 'single-select-button-attribute':
              return (
                <AttributesButtonSelect
                  filer_item={filer_item}
                  styles={styles}
                  key={`${filter_type}_${index}`}
                />
              );

            case 'single-select-attribute':
              return (
                <AttributeSingleSelectFilter
                  filer_item={filer_item}
                  styles={styles}
                  key={`${filter_type}_${index}`}
                />
              );

            default:
              return <></>;
          }
        })}
      </div>
    </div>
  );
};

export default AccountCustomFilterLayout;
