import React from 'react';
import { time_icon } from '../../Shared/Constant/Icons/AllIcons';
import moment from 'moment';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
const ListingSchedule = ({ data, styles, product, schedules }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <p
        className="flex items-center"
        style={get_dynamic_styles(styles?.text_styles)}
      >
        {schedules && time_icon}
        <span className="ml-[6px]">
          {schedules &&
            moment(schedules[0].start_time, 'HH:mm').format('hh:mm a')}
        </span>
        {schedules && <span className="ml-[6px]">to</span>}
        <span className="ml-[6px]">
          {schedules &&
            moment(schedules[0].end_time, 'HH:mm').format('hh:mm a')}
        </span>
      </p>
    </div>
  );
};

export default ListingSchedule;
