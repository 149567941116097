/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeSelector } from '../../../store/feature/storeSlice';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';
import { Switch } from '@headlessui/react';
import { SketchPicker, PhotoshopPicker } from 'react-color';
import OutsideClickHandler from 'react-outside-click-handler';
import Address_search from '../../Shared/Address_search';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';
import { General_text } from '../../../constant/Translation/General';
import { Listing_text } from '../../../constant/Translation/Listing';
import SelectDropDown from '../../Shared/SelectDropDown';
import AudioPicker from '../../../UI/FormFields/Audio';
import AllIcons from '../../Shared/Constant/Icons/AllIcons';
import VideoPicker from '../../../UI/FormFields/VideoPicker';
import Composer2 from '../../Shared/Composer/Composer2';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { cartAttributes, cartSelector } from '../../../store/feature/cartSlice';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';

const AttributesList = ({
  data,
  styles,
  cart_attributes,
  attributeData,
  setAttributeData,
}) => {
  return (
    <div
      style={{
        display: styles?.attributes_list_styles?.display,
        gridTemplateColumns: dynamic_text_convert({
          main_text: styles?.attributes_list_styles?.columns_box_percentage,
          check_by: '-1%',
          value: '',
        }),
        ...get_dynamic_styles(styles?.attributes_list_styles),
      }}
    >
      {cart_attributes?.map((attr) => {
        return (
          <div className="  block">
            {/* Field type 1 */}
            {attr.field_type === 1 && (
              <div className="      ">
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none   text-lg relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>

                <SelectDropDown
                  drop_down_values={attr.values?.map((item) => {
                    return { label: item.name, value: item.id };
                  })}
                  setSelectedItem={(item) => {
                    if (item) {
                      attributeData?.length > 0
                        ? setAttributeData([
                            { values: [item.value], id: attr?.id },
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                        : setAttributeData([
                            { values: [item.value], id: attr?.id },
                          ]);
                    } else {
                      setAttributeData([
                        ...attributeData?.filter(
                          (filter_att) => filter_att?.id !== attr?.id
                        ),
                      ]);
                    }
                  }}
                  selectedItem={
                    attributeData
                      ? attributeData?.filter(
                          (atData) => atData?.id === attr?.id
                        ).length > 0
                        ? {
                            label: attr.values.filter((item) => {
                              if (
                                attributeData?.filter(
                                  (atData) => atData?.id === attr?.id
                                )[0]?.values[0] == item.id
                              ) {
                                return item;
                              }
                            })[0].name,
                            value: attr.values.filter((item) => {
                              if (
                                attributeData?.filter(
                                  (atData) => atData?.id === attr?.id
                                )[0]?.values[0] == item.id
                              ) {
                                return item;
                              }
                            })[0].id,
                          }
                        : null
                      : null
                  }
                />
              </div>
            )}

            {/* Field type 2*/}
            {attr.field_type === 2 && (
              <div className="      ">
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                <MultiSelect
                  options={attr.values?.map((value, index) => {
                    return {
                      value: value?.id,
                      label: value.name,
                    };
                  })}
                  value={
                    attributeData
                      ?.filter((single_at) => single_at?.id == attr?.id)[0]
                      ?.values?.map((vl) => {
                        return {
                          value: attr.values?.filter(
                            (a_vl) => vl == a_vl?.id
                          )[0]?.id,
                          label: attr.values?.filter(
                            (a_vl) => vl == a_vl?.id
                          )[0].name,
                        };
                      }) || []
                  }
                  onChange={(e) => {
                    attributeData?.length > 0
                      ? e.length > 0
                        ? setAttributeData([
                            {
                              id: attr?.id,
                              values: e.map((singleValue) => singleValue.value),
                            },
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                        : setAttributeData([
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                      : setAttributeData([
                          {
                            id: attr?.id,
                            values: e.map((singleValue) => singleValue.value),
                          },
                        ]);
                  }}
                  labelledBy={Add_product_text.addproduct.select}
                  overrideStrings={{
                    allItemsAreSelected:
                      General_text.general.allItemsAreSelected,
                    clearSearch: General_text.general.clearSearch,
                    clearSelected: General_text.general.clearSelected,
                    noOptions: General_text.general.noOptions,
                    search: General_text.general.search,
                    selectAll: General_text.general.selectAll,
                    selectAllFiltered: General_text.general.selectAllFiltered,
                    selectSomeItems: General_text.general.selectSomeItems,
                    create: General_text.general.create,
                  }}
                />
              </div>
            )}

            {/* Field type 3 */}
            {attr.field_type === 3 && (
              <div className="      ">
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                <input
                  value={
                    attributeData?.filter(
                      (atData) => atData?.id === attr?.id
                    )[0]?.values[0]
                  }
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder={
                    Add_product_text.addproduct.type + ' ' + attr.name
                  }
                  onChange={(e) => {
                    attributeData?.length > 0
                      ? e.target.value.replace(/ /g, '').length > 0
                        ? setAttributeData([
                            { values: [e.target.value], id: attr?.id },
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                        : setAttributeData([
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                      : setAttributeData([
                          { values: [e.target.value], id: attr?.id },
                        ]);
                  }}
                />
              </div>
            )}

            {/* Field type 4*/}
            {attr.field_type === 4 && (
              <div className="     ">
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>

                <input
                  value={attributeData
                    ?.filter((atData) => atData?.id === attr?.id)[0]
                    ?.values.toString()}
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder={
                    Add_product_text.addproduct.multi_write_value_info
                  }
                  onChange={(e) => {
                    attributeData?.length > 0
                      ? e.target.value.replace(/ /g, '').length > 0
                        ? setAttributeData([
                            {
                              values: e.target.value.split(','),
                              id: attr?.id,
                            },
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                        : setAttributeData([
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                      : setAttributeData([
                          {
                            values: e.target.value.split(','),
                            id: attr?.id,
                          },
                        ]);
                  }}
                />
              </div>
            )}

            {/* Field type 5 */}
            {attr.field_type === 5 && (
              <>
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                {!attributeData?.filter((at_filter) => attr.id == at_filter.id)
                  .length > 0 ? (
                  <div className="mt-3">
                    <div>
                      <div className=" h-0 overflow-hidden">
                        <input
                          type="file"
                          value={''}
                          id={`fileUploadClick-${attr.id}`}
                          name="imageUpload"
                          onChange={(e) => {
                            attributeData?.length > 0
                              ? setAttributeData([
                                  {
                                    values: [e.target.files[0]],
                                    id: attr.id,
                                    uploadFile: true,
                                  },
                                  ...attributeData?.filter(
                                    (filter_att) => filter_att?.id !== attr?.id
                                  ),
                                ])
                              : setAttributeData([
                                  {
                                    values: [e.target.files[0]],
                                    id: attr.id,
                                    uploadFile: true,
                                  },
                                ]);
                          }}
                        />
                      </div>
                      <button
                        className=" flex flex-col items-center justify-center w-full p-3 border-2 border-dashed border-primary  "
                        onClick={() => fileUploadClick(attr.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-9 w-9"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p
                          className={
                            attr.optional
                              ? ''
                              : "after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                          }
                        >
                          {Add_product_text.addproduct.upload_attachment}
                        </p>
                      </button>
                    </div>
                  </div>
                ) : attributeData?.filter(
                    (at_filter) => attr.id == at_filter.id
                  )[0].uploadFile ? (
                  <div className=" mt-2  flex flex-col items-center px-[10px] py-[5px] border-2 border-dashed border-primary rounded-card  ">
                    <div className=" flex flex-col text-base  ">
                      <span>
                        {
                          attributeData?.filter(
                            (at_filter) => attr.id == at_filter.id
                          )[0].values[0].name
                        }
                      </span>
                    </div>
                    <div
                      className="flex justify-end cursor-pointer after:text-red-500 mt-2"
                      onClick={() =>
                        setAttributeData([
                          ...attributeData?.filter(
                            (filter_att) => filter_att?.id !== attr?.id
                          ),
                        ])
                      }
                    >
                      {General_text.general.cancel}
                    </div>
                  </div>
                ) : (
                  <div className=" mt-2  flex flex-col items-center px-[10px] py-[5px] border-2 border-dashed border-primary rounded-card  ">
                    <div className=" flex flex-col text-base  ">
                      <span>
                        {
                          attributeData
                            ?.filter((at_filter) => attr.id == at_filter.id)[0]
                            .values[0].split('/')
                            .reverse()[0]
                        }
                      </span>
                    </div>
                    <div
                      className="flex justify-end cursor-pointer after:text-red-500 mt-2"
                      onClick={() =>
                        setAttributeData([
                          ...attributeData?.filter(
                            (filter_att) => filter_att?.id !== attr?.id
                          ),
                        ])
                      }
                    >
                      {General_text.general.cancel}
                    </div>
                  </div>
                )}
              </>
            )}
            {/* Field type 6 */}
            {attr.field_type === 6 && (
              <div className="">
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                <Composer2
                  contents={
                    attributeData?.filter(
                      (atData) => atData?.id === attr?.id
                    )[0]?.values[0] || ' '
                  }
                  id={attr?.id}
                  dependents={attributeData}
                  setContents={(text) => {
                    attributeData?.length > 0
                      ? text.replace(/ /g, '').length > 0
                        ? setAttributeData([
                            { values: [text], id: attr?.id },
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                        : setAttributeData([
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                      : setAttributeData([{ values: [text], id: attr?.id }]);
                  }}
                  mainClass={' border-b-2 border-gray-200 '}
                />
              </div>
            )}

            {/* Field type 7 */}
            {attr.field_type === 7 && (
              <div className="      ">
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                <input
                  type="datetime-local"
                  value={
                    attributeData
                      ?.filter((atData) => atData?.id === attr?.id)[0]
                      ?.values.toString()
                      ? attributeData
                          ?.filter((atData) => atData?.id === attr?.id)[0]
                          ?.values.toString()
                      : null
                  }
                  onChange={(e) => {
                    const date = e.target.value;
                    attributeData?.length > 0
                      ? date
                        ? setAttributeData([
                            {
                              values: [
                                moment(date).format('yyyy-MM-DD HH:mm:ss'),
                              ],
                              id: attr?.id,
                            },
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                        : setAttributeData([
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                      : setAttributeData([
                          {
                            values: [
                              moment(date).format('yyyy-MM-DD HH:mm:ss'),
                            ],
                            id: attr?.id,
                          },
                        ]);
                  }}
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary   bg-transparent
                  "
                />
              </div>
            )}

            {/* Field type 8 */}

            {attr.field_type === 8 && (
              <div className="     ">
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                <input
                  value={attributeData
                    ?.filter((atData) => atData?.id === attr?.id)[0]
                    ?.values.toString()}
                  type="number"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder={'1234'}
                  onChange={(e) => {
                    attributeData?.length > 0
                      ? e.target.value.replace(/ /g, '').length > 0
                        ? setAttributeData([
                            {
                              values: [e.target.value],
                              id: attr?.id,
                            },
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                        : setAttributeData([
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ])
                      : setAttributeData([
                          {
                            values: [e.target.value],
                            id: attr?.id,
                          },
                        ]);
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            )}

            {/* Field type 9*/}

            {attr.field_type === 9 && (
              <div className="     ">
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                <div className="mt-3">
                  <Switch
                    checked={
                      attributeData?.filter(
                        (atData) => atData?.id === attr?.id
                      )[0]?.values[0]
                    }
                    onChange={(value) => {
                      attributeData?.length > 0
                        ? value === true
                          ? setAttributeData([
                              {
                                values: [value],
                                id: attr?.id,
                              },
                              ...attributeData?.filter(
                                (filter_att) => filter_att?.id !== attr?.id
                              ),
                            ])
                          : setAttributeData([
                              ...attributeData?.filter(
                                (filter_att) => filter_att?.id !== attr?.id
                              ),
                            ])
                        : value === true &&
                          setAttributeData([
                            {
                              values: [value],
                              id: attr?.id,
                            },
                          ]);
                    }}
                    className={`${
                      attributeData?.filter(
                        (atData) => atData?.id === attr?.id
                      )[0]?.values[0]
                        ? 'bg-primary'
                        : 'bg-gray-400'
                    }
          relative inline-flex flex-shrink-0 h-[28px] w-[64px] border-2 border-primary rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        attributeData?.filter(
                          (atData) => atData?.id === attr?.id
                        )[0]?.values[0]
                          ? 'ltr:translate-x-9 rtl:-translate-x-9'
                          : 'ltr:translate-x-0 rtl:-translate-x-0'
                      }
            pointer-events-none inline-block h-[24px] w-[24px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                    />
                  </Switch>
                </div>
              </div>
            )}

            {/* Field type 10*/}
            {attr.field_type === 10 && (
              <div className="     ">
                <div className="mt-3">
                  <div className=" w-full flex items-start justify-start   gap-1  mb-3 ">
                    <span
                      className={
                        attr.optional
                          ? 'flex-none text-gray-700  text-lg '
                          : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                      }
                    >
                      {attr.name}
                    </span>
                    {attr?.tooltip && (
                      <button className="tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  "
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                          />
                        </svg>

                        <span className="top_hover text-primary">
                          {attr?.tooltip}
                        </span>
                      </button>
                    )}
                  </div>
                  <button
                    id="primary_color"
                    className="w-full grid grid-cols-2 h-12 border  border-gray-500 rounded-button "
                    onClick={() =>
                      setdisplayColorPicker({
                        [`color_attribute_${attr?.id}`]: true,
                      })
                    }
                  >
                    <div className="h-full flex items-center justify-center  ">
                      {attributeData?.filter(
                        (atData) => atData?.id === attr?.id
                      )[0]?.values[0] || 'Select color'}
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          attributeData?.filter(
                            (atData) => atData?.id === attr?.id
                          )[0]?.values[0] || '#000',
                      }}
                      className={' w-full h-full rounded-button'}
                    ></div>
                  </button>
                  {displayColorPicker?.[`color_attribute_${attr?.id}`] && (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setdisplayColorPicker(null);
                      }}
                    >
                      <div className="absolute z-[50]">
                        <SketchPicker
                          color={
                            attributeData?.filter(
                              (atData) => atData?.id === attr?.id
                            )[0]?.values[0] || '#000'
                          }
                          onChange={(color) =>
                            attributeData?.length > 0
                              ? color.hex
                                ? setAttributeData([
                                    {
                                      values: [color.hex],
                                      id: attr?.id,
                                    },
                                    ...attributeData?.filter(
                                      (filter_att) =>
                                        filter_att?.id !== attr?.id
                                    ),
                                  ])
                                : setAttributeData([
                                    ...attributeData?.filter(
                                      (filter_att) =>
                                        filter_att?.id !== attr?.id
                                    ),
                                  ])
                              : setAttributeData([
                                  {
                                    values: [color.hex],
                                    id: attr?.id,
                                  },
                                ])
                          }
                        />
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
            )}

            {/* Field type 11 */}
            {attr.field_type === 11 && (
              <>
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                {!attributeData?.filter((at_filter) => attr.id == at_filter.id)
                  .length > 0 ? (
                  <div className="mt-3">
                    <div>
                      <div className=" h-0 overflow-hidden">
                        <input
                          type="file"
                          value={''}
                          id={`fileUploadClick-${attr.id}`}
                          name="imageUpload"
                          accept=".json"
                          onChange={(e) => {
                            const file_name = e.target.files[0].name;

                            if (
                              file_name.split('.')[
                                file_name.split('.').length - 1
                              ] === 'json'
                            ) {
                              const fileReader = new FileReader();
                              fileReader.readAsText(e.target.files[0], 'UTF-8');
                              fileReader.onload = (e) => {
                                attributeData?.length > 0
                                  ? setAttributeData([
                                      {
                                        values: [JSON.parse(e.target.result)],
                                        id: attr.id,
                                      },
                                      ...attributeData?.filter(
                                        (filter_att) =>
                                          filter_att?.id !== attr?.id
                                      ),
                                    ])
                                  : setAttributeData([
                                      {
                                        values: [JSON.parse(e.target.result)],
                                        id: attr.id,
                                      },
                                    ]);
                              };
                            }
                          }}
                        />
                      </div>
                      <button
                        className=" flex flex-col items-center justify-center w-full p-3 border-2 border-dashed border-primary  "
                        onClick={() => fileUploadClick(attr.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-9 w-9"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p
                          className={
                            attr.optional
                              ? ''
                              : "after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                          }
                        >
                          {Add_product_text.addproduct.upload_json_file}
                        </p>
                      </button>
                    </div>
                  </div>
                ) : attributeData?.filter(
                    (at_filter) => attr.id == at_filter.id
                  )[0].uploadFile ? (
                  <div className=" mt-2  flex flex-col items-center px-[10px] py-[5px] border-2 border-dashed border-primary rounded-card  ">
                    <div className=" flex flex-col text-base  ">
                      <span>
                        {
                          attributeData?.filter(
                            (at_filter) => attr.id == at_filter.id
                          )[0].values[0].name
                        }
                      </span>
                    </div>
                    <div
                      className="flex justify-end cursor-pointer after:text-red-500 mt-2"
                      onClick={() =>
                        setAttributeData([
                          ...attributeData?.filter(
                            (filter_att) => filter_att?.id !== attr?.id
                          ),
                        ])
                      }
                    >
                      {General_text.general.cancel}
                    </div>
                  </div>
                ) : (
                  <div className=" mt-2  flex flex-col items-center px-[10px] py-[5px] border-2 border-dashed border-primary rounded-card  ">
                    <div className=" flex flex-col text-base  ">
                      <span>
                        {JSON.stringify(
                          attributeData?.filter(
                            (at_filter) => attr.id == at_filter.id
                          )[0]
                        ).length > 50 ? (
                          <>
                            {JSON.stringify(
                              attributeData?.filter(
                                (at_filter) => attr.id == at_filter.id
                              )[0]
                            ).substring(0, 50) + '...'}
                            <button
                              onClick={() => {
                                /* Copy the text inside the text field */
                                navigator.clipboard.writeText(
                                  JSON.stringify(
                                    attributeData?.filter(
                                      (at_filter) => attr.id == at_filter.id
                                    )[0]
                                  )
                                );

                                /* Alert the copied text */
                                alert('Copied the value');
                              }}
                              className="text-primary text-sm"
                            >
                              {General_text.general.copy}
                            </button>
                          </>
                        ) : (
                          JSON.stringify(
                            attributeData?.filter(
                              (at_filter) => attr.id == at_filter.id
                            )[0]
                          )
                        )}
                      </span>
                    </div>
                    <div
                      className="flex justify-end cursor-pointer after:text-red-500 mt-2"
                      onClick={() =>
                        setAttributeData([
                          ...attributeData?.filter(
                            (filter_att) => filter_att?.id !== attr?.id
                          ),
                        ])
                      }
                    >
                      {General_text.general.cancel}
                    </div>
                  </div>
                )}
              </>
            )}
            {/* Field type 12 */}
            {attr.field_type === 12 && (
              <>
                <div className=" w-full flex items-start justify-start   gap-1   ">
                  <span
                    className={
                      attr.optional
                        ? 'flex-none text-gray-700  text-lg '
                        : "flex-none  text-lg  relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] "
                    }
                  >
                    {attr.name}
                  </span>
                  {attr?.tooltip && (
                    <button className="tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_hover text-primary">
                        {attr?.tooltip}
                      </span>
                    </button>
                  )}
                </div>
                <div className="">
                  <Address_search
                    coordinates={
                      attributeData?.filter(
                        (atData) => atData?.id === attr?.id
                      )[0]?.values[0]
                    }
                    setCoordinates={(coordinate) => {
                      if (coordinate !== null) {
                        attributeData?.length > 0
                          ? coordinate.latitude
                            ? setAttributeData([
                                {
                                  values: [
                                    {
                                      latitude: coordinate.latitude,
                                      longitude: coordinate.longitude,
                                    },
                                  ],
                                  id: attr?.id,
                                },
                                ...attributeData?.filter(
                                  (filter_att) => filter_att?.id !== attr?.id
                                ),
                              ])
                            : setAttributeData([
                                ...attributeData?.filter(
                                  (filter_att) => filter_att?.id !== attr?.id
                                ),
                              ])
                          : setAttributeData([
                              {
                                values: [
                                  {
                                    latitude: coordinate.latitude,
                                    longitude: coordinate.longitude,
                                  },
                                ],
                                id: attr?.id,
                              },
                            ]);
                      } else {
                        attributeData?.length > 0 &&
                          setAttributeData([
                            ...attributeData?.filter(
                              (filter_att) => filter_att?.id !== attr?.id
                            ),
                          ]);
                      }
                    }}
                  />
                </div>
              </>
            )}

            {/* Field type 13 */}
            {attr.field_type === 13 && (
              <>
                <AudioPicker
                  title={attr.name}
                  isOptional={attr.optional}
                  tooltip_text={attr?.tooltip}
                  current_value={
                    attributeData?.filter(
                      (at_filter) => attr.id == at_filter.id
                    )[0]?.values[0]?.name
                  }
                  add_file_title={General_text.general.upload_audio}
                  button_icon={AllIcons.audio_icon}
                  set_new_value={(files) => {
                    attributeData?.length > 0
                      ? setAttributeData([
                          {
                            values: [files[0]],
                            id: attr.id,
                            uploadFile: true,
                          },
                          ...attributeData?.filter(
                            (filter_att) => filter_att?.id !== attr?.id
                          ),
                        ])
                      : setAttributeData([
                          {
                            values: [files[0]],
                            id: attr.id,
                            uploadFile: true,
                          },
                        ]);
                  }}
                  delete_file={() =>
                    setAttributeData([
                      ...attributeData?.filter(
                        (filter_att) => filter_att?.id !== attr?.id
                      ),
                    ])
                  }
                  max_size={200}
                  min_size={0}
                  setIsError={setShowError}
                  setErrorMessage={setErrormessage}
                />
              </>
            )}
            {/* Field type 14 */}
            {attr.field_type === 14 && (
              <VideoPicker
                title={attr.name}
                isOptional={attr.optional}
                tooltip_text={attr?.tooltip}
                current_value={
                  attributeData?.filter(
                    (at_filter) => attr.id == at_filter.id
                  )[0]?.values[0]?.name
                }
                add_file_title={General_text.general.upload_video}
                set_new_value={(files) => {
                  attributeData?.length > 0
                    ? setAttributeData([
                        {
                          values: [files[0]],
                          id: attr.id,
                          uploadFile: true,
                        },
                        ...attributeData?.filter(
                          (filter_att) => filter_att?.id !== attr?.id
                        ),
                      ])
                    : setAttributeData([
                        {
                          values: [files[0]],
                          id: attr.id,
                          uploadFile: true,
                        },
                      ]);
                }}
                delete_file={() =>
                  setAttributeData([
                    ...attributeData?.filter(
                      (filter_att) => filter_att?.id !== attr?.id
                    ),
                  ])
                }
                max_size={500}
                min_size={0}
                setIsError={setShowError}
                setErrorMessage={setErrormessage}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AttributesList;
