export const MainImageStyles = {
  border_radius: '8px',
  max_height: '100%',
  width: '100%',
  object_fit: 'contain',
};

// 0px 8px 28px rgba(0, 0, 0, 0.05)
export const CardMainDefaultStyles = {
  min_height: '60px',
  border_radius: '8px',
  box_shadow: '',
  background: '',
  padding_right: '16px',
  padding_left: '16px',
  padding_top: '16px',
  padding_bottom: '16px',
};

export const CardBoxDefaultStyles = {
  margin_left: '0px',
  margin_right: '0px',
};

export const ListingDetailsMainDefaultStyles = {
  background: '',
  width: '100%',
};

export const ListingDetailsBoxDefaultStyles = {
  background: '',
  gap: '24px',
  max_width: '1400px',
  margin_bottom: '20px',
  margin_top: '10px',
  margin_left: '0px',
  margin_right: '0px',
  padding_right: '16px',
  padding_left: '16px',
  padding_bottom: '16px',
};

export const DefaultColumnStyles = {
  flex_direction: 'column',
  column_gap: '24px',
  row_gap: '24px',
};
