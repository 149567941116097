import Link from 'next/link';
import React, { useRef, useState, useEffect } from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { userAttributesInfo } from '../../store/feature/authSlice';
import { loading_icon } from '../Shared/Constant/Icons/ComoserIcons';

const CustomCTAWithUserAttribute = ({ data, styles, listing_details }) => {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Load reCAPTCHA script dynamically
    const loadRecaptchaScript = () => {
      const script = document.createElement('script');
      script.src =
        'https://www.google.com/recaptcha/enterprise.js?render=6Ld8PO8qAAAAAAn1fR4yTaUYwKsnL4eaLnkNfLkP';
      script.async = true;
      script.defer = true;
      script.onload = () => setIsRecaptchaLoaded(true);
      document.head.appendChild(script);
    };

    loadRecaptchaScript();

    // Cleanup function to remove script when component unmounts
    return () => {
      const script = document.querySelector(
        'script[src*="recaptcha/enterprise.js"]'
      );
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, []);

  const getUserAttributesInfo = async () => {
    if (!isRecaptchaLoaded) return;
    setIsLoading(true);
    const recaptchaToken = await window.grecaptcha.enterprise?.execute(
      '6Ld8PO8qAAAAAAn1fR4yTaUYwKsnL4eaLnkNfLkP',
      {
        action: 'USER_PROFILE',
      }
    );
    dispatch(
      userAttributesInfo({
        userID: listing_details?.account?.user?.id,
        recaptcha_token: recaptchaToken,
      })
    ).then((res) => {
      setIsLoading(false);
      if (res?.payload?.user) {
        const type = data?.type;

        const user_details = res?.payload?.user;
        let selected_value;
        if (type == 'user_information') {
          if (data?.user_information == 'Name') {
            selected_value =
              user_details?.first_name + ' ' + user_details?.last_name;
          } else if (data?.user_information == 'Email') {
            selected_value = user_details?.email;
          } else if (data?.user_information == 'Phone') {
            selected_value = `+${user_details?.dial_code}${user_details?.mobile}`;
          } else if (data?.user_information == 'Profile Picture') {
            selected_value = user_details?.profile_pic;
          }
        } else if (type == 'user_attribute') {
          selected_value = user_details?.attributes?.filter(
            (it) => it.id == data?.attribute_id
          )[0]?.values[0];
        }

        if (data?.action_type == 'whatsapp') {
          window.open(`https://wa.me/${selected_value}`, '_blank');
        } else if (data?.action_type == 'email') {
          window.open(`mailto:${selected_value}`);
        } else if (data?.action_type == 'phone') {
          window.open(`tel:${selected_value}`);
        } else if (data?.action_type == 'link') {
          window.open(selected_value, '_blank');
        }
      }
    });
  };

  return (
    <div
      style={get_dynamic_styles_with_default_value(
        styles?.box_styles,
        CardMainDefaultStyles
      )}
    >
      {' '}
      <div
        className=" "
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        <button
          className="     rounded-button  flex items-center justify-center  gap-2 "
          type="button"
          target={data?.button_target ?? '_blank'}
          style={get_dynamic_styles(styles?.button_styles)}
          onClick={() => {
            // setIsShow(!isShow);
            getUserAttributesInfo();
          }}
        >
          {data?.icon && (
            <img
              className="w-auto object-cover  "
              style={get_dynamic_styles(styles?.icon_styles)}
              src={data?.icon}
              alt="image"
            />
          )}
          {data?.button_title && (
            <p
              className="text-lg font-semibold text-gray-700"
              style={get_dynamic_styles(styles?.text_styles)}
            >
              {get_data_by_current_language({
                data_list: data?.translated_data,
                key_name: 'button_title',
                default_data: data?.button_title,
              })}
            </p>
          )}
          {isLoading && loading_icon}
        </button>
      </div>
    </div>
  );
};

export default CustomCTAWithUserAttribute;
