/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import {
  angle_down,
  check_icon,
  filter_icon,
  star_icon,
} from './Constant/Icons/AllIcons';
import tradly from 'tradly';
import { useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import {
  convertTimeinto12Hrs,
  getDatesArray2,
  priceRange,
} from './Constant/Constant';
import OutsideClickHandler from 'react-outside-click-handler';
import { getDatesArray, getTimeDifference } from './Constant/Constant';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import moment from 'moment';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { fetch_all_categories } from '../../constant/fetch_all_categories';
import { Filter_text } from '../../constant/Translation/Filter';
import { useSelector } from 'react-redux';
import { categorySelector } from '../../store/feature/categorySlice';

const DefaultOpenListingFilter = ({ data, styles }) => {
  const [MARKETPLACE_MODULES, setMARKETPLACE_MODULES] = useState(2);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSeeAllCategories, setIsSeeAllCategories] = useState(false);
  const [allCategories, setAllCategories] = useState(null);
  const [allAttributes, setAllAttributes] = useState(null);

  const router = useRouter();
  const category_id = router?.query?.category_id;
  const country = router?.query?.country;
  const city = router?.query?.city;
  const sort = router?.query?.sort;
  const rating = router?.query?.rating;
  const price_from = router?.query?.price_from;
  const price_to = router?.query?.price_to;
  const attribute_value_id = router?.query?.attribute_value_id;
  const [activeParent, setActiveParent] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAtValues, setSelectedAtValues] = useState([]);

  const start_at = router?.query?.start_at;
  const end_at = router?.query?.end_at;
  const [range_value, setRange_value] = useState([1]);
  const [changed_value, setChanged_value] = useState(['00:00:00', '23:59:59']);
  const [is_value_changed, setIs_value_changed] = useState(false);

  const [listingsCities, setListingsCities] = useState(null);
  const [listingsCountries, setListingsCountries] = useState(null);
  const [isSeeAllCountries, setIsSeeAllCountries] = useState(false);
  const [isSeeAllCities, setIsSeeAllCities] = useState(false);
  // const [selectedCities, setSelectedCities] = useState([]);
  // const [selectedCountries, setSelectedCountries] = useState([]);
  let dates = getDatesArray2();

  // Selectors
  const { all_categories } = useSelector(categorySelector);
  //
  useEffect(() => {
    if (all_categories?.length > 0) {
      setAllCategories(fetch_all_categories(all_categories, true));
    }
  }, [all_categories]);

  // Fetch Categories , attributes ,locations
  useEffect(() => {
    // Attributes
    let attribute_params = { type: 'listings' };
    if (category_id) {
      attribute_params['category_id'] = category_id;
    }
    axios.get('/api/attributes', { params: attribute_params }).then((res) => {
      setAllAttributes(res.data.attributes);
    });

    if (category_id) {
      setSelectedCategories(category_id.split(','));
    }
    if (attribute_value_id) {
      setSelectedAtValues(attribute_value_id.split(','));
    }

    // City
    axios
      .get('/api/l/locations', { params: { type: 'city', page: 1 } })
      .then((res) => {
        setListingsCities(res.data.values);
      });
    // Countries
    axios
      .get('/api/l/locations', {
        params: { type: 'country', page: 1 },
      })
      .then((res) => {
        setListingsCountries(res.data.values);
      });
  }, [router.query]);

  // set marketplace module and start at time
  useEffect(() => {
    setMARKETPLACE_MODULES(Number(TYPE_CONSTANT.MARKETPLACE_MODULES));
    if (start_at !== undefined) {
      setChanged_value([
        start_at.split('T')[1].replace('Z', ''),
        end_at.split('T')[1].replace('Z', ''),
      ]);
    }
  }, [0]);

  // Toggle function in arrow icon
  const toggleChildren = (e, id, children) => {
    e.stopPropagation();

    const findParent = activeParent?.find((parentId) => parentId === id);
    let filterActive;
    if (findParent !== undefined) {
      filterActive = activeParent?.filter((parentId) => parentId !== id);
    }

    setActiveParent(
      findParent === undefined ? [...activeParent, id] : [...filterActive]
    );
  };

  // filter by category
  const filter_by_category = (id) => {
    const check = selectedCategories?.find((ct) => ct == id);
    if (check === undefined) {
      setSelectedCategories([...selectedCategories, id]);
      router.push({
        query: {
          ...router.query,
          category_id: [...selectedCategories, id].toString(''),
        },
      });
    } else {
      const filter = selectedCategories?.filter((ct) => ct != id);
      setSelectedCategories(filter);
      if (filter.length > 0) {
        router.push({
          query: {
            ...router.query,
            category_id: [...filter].toString(''),
          },
        });
      } else {
        const queries = { ...router.query };
        delete queries.category_id;
        router.push({
          query: { ...queries },
        });
      }
    }
  };

  // filter by attribute
  const filter_by_attribute_value = (id) => {
    const check = selectedAtValues?.find((at) => at == id);
    if (check === undefined) {
      setSelectedAtValues([...selectedAtValues, id]);
      router.push({
        query: {
          ...router.query,
          attribute_value_id: [...selectedAtValues, id].toString(''),
        },
      });
    } else {
      const filter = selectedAtValues?.filter((at) => at != id);
      setSelectedAtValues(filter);
      if (filter.length > 0) {
        router.push({
          query: {
            ...router.query,
            attribute_value_id: [...filter].toString(''),
          },
        });
      } else {
        const queries = { ...router.query };
        delete queries.attribute_value_id;
        router.push({
          query: { ...queries },
        });
      }
    }
  };

  // filter by country
  const filter_by_country = (value) => {
    if (country == value) {
      const queries = { ...router.query };
      delete queries.country;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          country: value,
        },
      });
    }
  };
  // filter by City
  const filter_by_city = (value) => {
    if (city == value) {
      if (router.pathname.startsWith(`/l/c/[city]`)) {
        router.push({ pathname: '/l/c', query: {} });
      } else {
        const queries = { ...router.query };
        delete queries.city;
        router.push({
          query: { ...queries },
        });
      }
    } else {
      router.push({
        query: {
          ...router.query,
          city: value,
        },
      });
    }
  };
  // filter by price rang
  const filter_by_price_rang = (value) => {
    if (sort == value) {
      const queries = { ...router.query };
      delete queries.sort;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          sort: value,
        },
      });
    }
  };

  // filter by rating
  const filter_by_rating = (rating_value) => {
    if (rating == rating_value) {
      const queries = { ...router.query };
      delete queries.rating;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          rating: rating_value,
        },
      });
    }
  };

  // filter by date
  const filter_by_date = (sdate, edate) => {
    if (sdate == start_at) {
      const queries = { ...router.query };
      delete queries.start_at;
      delete queries.end_at;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          start_at: sdate,
          end_at: edate,
        },
      });
    }
  };

  // Change time for date
  const change_time = () => {
    if (start_at == undefined) {
      router.push({
        query: {
          ...router.query,
          start_at: `${moment(new Date()).format('YYYY-MM-DD')}T${
            changed_value[0]
          }Z`,
          end_at: `${moment(new Date()).add(1, 'days').format('YYYY-MM-DD')}T${
            changed_value[1]
          }Z`,
        },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          start_at: `${start_at.split('T')[0]}T${changed_value[0]}Z`,
          end_at: `${end_at.split('T')[0]}T${changed_value[1]}Z`,
        },
      });
    }
    setIs_value_changed(false);
  };

  const onSliderChange = (value) => {
    const value1 = Number(value[0]) < 10 ? `0${value[0]}` : value[0];
    const value2 = Number(value[1]) < 10 ? `0${value[0]}` : value[1];
    setChanged_value([`${value1}:00:00`, `${value2}:59:59`]);
    setIs_value_changed(true);
  };

  //Filter by price
  const [priceRage, setPriceRange] = useState({
    from: price_from ?? 0,
    to: price_to ?? 0,
  });

  const onPriceSliderChange = (value) => {
    setPriceRange({
      from: value[0],
      to: value[1],
    });
  };

  // filter by price
  const filter_by_price_range = (s) => {
    if (
      Number(priceRage?.from) >= 0 &&
      Number(priceRage?.to) > 0 &&
      Number(priceRage?.to) > Number(priceRage?.from)
    ) {
      router.push({
        query: {
          ...router.query,
          price_from: priceRage?.from,
          price_to: priceRage?.to,
        },
      });
    } else {
    }
  };

  return (
    <div className=" relative  bg-[#FEFEFE] shadow-c-sm ">
      <div
        className={[
          '  rounded-t-button    md:w-[245px]     flex items-center px-4 py-4 gap-3  z-30  cursor-pointer  w-[245px]  relative min-w-[106px] ',
        ].join(' ')}
      >
        <span>{filter_icon}</span>
        <p className=" text-primary font-medium text-base  ">
          {Filter_text.filter.title}
        </p>
      </div>

      <div
        className={[
          'bg-[#FEFEFE] w-[245px]  rounded-b-button transition duration-1000 h-[78%]  px-4  pb-7 overflow-auto scrollbar  scrollbar-thin   scrollbar-track-gray-100  scrollbar-thumb-gray-300    ',
        ].join(' ')}
      >
        {/* Dates Array */}
        {data?.show_dates && (
          <div className="  mb-3">
            <Swiper
              slidesPerView="auto"
              slidesPerGroup={1}
              spaceBetween={16}
              loop={false}
              navigation={false}
            >
              {dates?.map((date, i) => {
                return (
                  <SwiperSlide
                    className=""
                    key={date}
                    style={{
                      width: '70px',
                      minHeight: '30px',
                    }}
                  >
                    {i == 0 ? (
                      <button
                        className={[
                          ' w-full h-[30px] flex items-center justify-center cursor-pointer   border border-primary rounded-button transition duration-700 hover:bg-primary hover:text-white mr-1  text-sm',
                          start_at == undefined && 'bg-primary text-white',
                        ].join(' ')}
                        id={date}
                        onClick={() => {
                          const queries = { ...router.query };
                          delete queries.start_at;
                          delete queries.end_at;
                          router.push({
                            query: { ...queries },
                          });
                        }}
                      >
                        {Filter_text.filter.all}
                      </button>
                    ) : (
                      <button
                        className={[
                          ' w-full h-[30px] flex items-center justify-center cursor-pointer   border border-primary rounded-button transition duration-700 hover:bg-primary hover:text-white mr-1  text-sm',
                          start_at?.split('T')[0] ==
                            `${moment(date).format('YYYY-MM-DD')}` &&
                            'bg-primary text-white',
                        ].join(' ')}
                        id={date}
                        onClick={() => {
                          filter_by_date(
                            `${moment(date).format('YYYY-MM-DD')}T${
                              changed_value[0]
                            }Z`,
                            `${moment(date)
                              .add(1, 'days')
                              .format('YYYY-MM-DD')}T${changed_value[1]}Z`
                          );
                        }}
                      >
                        {i == 1
                          ? Filter_text.filter.today
                          : moment(date).format('ddd D')}
                      </button>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}

        {/* Time picker */}
        {MARKETPLACE_MODULES === 2 && (
          <div className="pr-2 mb-3">
            <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
              <span className=" cursor-pointer">{Filter_text.filter.time}</span>
            </h4>
            <Range
              className="text-primary"
              allowCross={false}
              max={23}
              defaultValue={[0, 23]}
              onChange={onSliderChange}
            />
            <div className="flex justify-between items-center  mt-3">
              <p className=" block text-gray-500  text-xs">
                {convertTimeinto12Hrs(changed_value[0])} -{' '}
                {convertTimeinto12Hrs(changed_value[1])}
              </p>
              {is_value_changed && (
                <button
                  className="px-2 py-1 bg-primary rounded-button text-white text-sm"
                  onClick={() => change_time()}
                >
                  {Filter_text.filter.done}
                </button>
              )}
            </div>
          </div>
        )}

        {/* Price Range Filter */}
        <div className=" mb-3 w-full">
          <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
            <span className=" cursor-pointer">{Filter_text.filter.price}</span>
          </h4>
          <div className=" w-full relative flex items-center   justify-start gap-3 ">
            <div className=" flex-grow  ">
              <input
                type="number"
                className=" w-full h-full text-center px-0.5 outline-none focus:border-primary  border border-gray-600  rounded-input "
                value={priceRage?.from.toString()}
                onChange={(e) =>
                  setPriceRange((prev) => ({
                    from: e.target.value,
                    to: prev.to,
                  }))
                }
              />
            </div>
            <span className=" flex-none text-lg font-medium text-gray-900 ">
              {Filter_text.filter.to}
            </span>
            <div className=" flex-grow  ">
              <input
                type="number"
                className=" w-full h-full px-0.5 text-center outline-none focus:border-primary   border border-gray-600 rounded-input "
                value={priceRage?.to.toString()}
                onChange={(e) =>
                  setPriceRange((prev) => ({
                    from: prev.from,
                    to: e.target.value,
                  }))
                }
              />
            </div>

            <button
              className=" flex-none btn-primary-outline"
              onClick={() => filter_by_price_range()}
            >
              {Filter_text.filter.go}
            </button>
          </div>
          <Range
            className="text-primary mt-3  "
            allowCross={false}
            max={TYPE_CONSTANT.LISTINGS_CONFIGS?.price_range_max_value ?? 500}
            defaultValue={[0, 500]}
            onChange={onPriceSliderChange}
          />
        </div>
        {/* Categories Part */}
        {data?.show_category && (
          <div>
            <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
              <span className=" cursor-pointer">
                {Filter_text.filter.category}
              </span>
              {category_id === undefined && <span>{check_icon}</span>}
            </h4>
            {allCategories?.map((item, index, array) => {
              if (array.length > 4) {
                if (index < 4) {
                  return (
                    <p
                      className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center  "
                      key={Math.random()}
                      // onClick={() => filter_by_category(item.id)}
                    >
                      <span className=" ">{item.name}</span>
                      <input
                        className=" form-check-input appearance-none h-3 w-3 border border-gray-400 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                        type="checkbox"
                        checked={
                          selectedCategories?.includes(`${item.id}`)
                            ? true
                            : false
                        }
                        onChange={() => filter_by_category(item.id)}
                      />
                      {/* {selectedCategories?.includes(`${item.id}`) && (
                          
                        )} */}
                    </p>
                  );
                }
                if (index === 4) {
                  return (
                    <button
                      className=" text-xs text-primary font-semibold py-[7px]  flex justify-between items-center  cursor-pointer w-full"
                      onClick={() => setIsSeeAllCategories(!isSeeAllCategories)}
                    >
                      <span>
                        {Filter_text.filter.see_all}{' '}
                        {Filter_text.filter.category}
                      </span>
                      <span
                        className={isSeeAllCategories && 'transform rotate-180'}
                      >
                        {angle_down}
                      </span>
                    </button>
                  );
                }
              } else {
                return (
                  <p
                    className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center  "
                    key={Math.random()}
                    // onClick={() => filter_by_category(item.id)}
                  >
                    <span className=" ">{item.name}</span>
                    <input
                      className=" form-check-input appearance-none h-3 w-3 border border-gray-400 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                      type="checkbox"
                      checked={
                        selectedCategories?.includes(`${item.id}`)
                          ? true
                          : false
                      }
                      onChange={() => filter_by_category(item.id)}
                    />
                    {/* {selectedCategories?.includes(`${item.id}`) && (
                          
                        )} */}
                  </p>
                );
              }
            })}

            {isSeeAllCategories &&
              allCategories?.map((item, index, array) => {
                if (array.length > 4) {
                  if (index >= 4) {
                    return (
                      <p
                        className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center  "
                        key={Math.random()}
                        // onClick={() => filter_by_category(item.id)}
                      >
                        <span className=" ">{item.name}</span>
                        <input
                          className=" form-check-input appearance-none h-3 w-3 border border-gray-400 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                          type="checkbox"
                          checked={
                            selectedCategories?.includes(`${item.id}`)
                              ? true
                              : false
                          }
                          onChange={() => filter_by_category(item.id)}
                        />
                      </p>
                    );
                  }
                }
              })}
          </div>
        )}

        {/* Countries */}
        {data?.show_country && listingsCountries?.length > 0 && (
          <div>
            <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
              <span className=" cursor-pointer">
                {Filter_text.filter.country}
              </span>
            </h4>
            {listingsCountries?.map((item, index, array) => {
              if (array.length > 4) {
                if (index < 4) {
                  return (
                    <p
                      className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                      key={Math.random()}
                      onClick={() => filter_by_country(item)}
                    >
                      <span className=" ">{item}</span>

                      <input
                        className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                        type="radio"
                        checked={country === item ? true : false}
                      />
                    </p>
                  );
                }
                if (index === 4) {
                  return (
                    <button
                      className=" text-xs text-primary font-semibold py-[7px]  flex justify-between items-center  cursor-pointer w-full"
                      onClick={() => setIsSeeAllCountries(!isSeeAllCountries)}
                    >
                      <span>
                        {Filter_text.filter.see_all}{' '}
                        {Filter_text.filter.country}
                      </span>
                      <span
                        className={isSeeAllCountries && 'transform rotate-180'}
                      >
                        {angle_down}
                      </span>
                    </button>
                  );
                }
              } else {
                return (
                  <p
                    className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                    key={Math.random()}
                    onClick={() => filter_by_country(item)}
                  >
                    <span className=" ">{item}</span>
                    <input
                      className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                      type="radio"
                      checked={country === item ? true : false}
                    />
                  </p>
                );
              }
            })}

            {isSeeAllCountries &&
              listingsCountries?.map((item, index, array) => {
                if (array.length > 4) {
                  if (index > 4) {
                    return (
                      <p
                        className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                        key={Math.random()}
                        onClick={() => filter_by_country(item)}
                      >
                        <span className=" ">{item}</span>

                        <input
                          className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                          type="radio"
                          checked={country === item ? true : false}
                        />
                      </p>
                    );
                  }
                }
              })}
          </div>
        )}

        {/* City */}

        {data?.show_city && listingsCities?.length > 0 && (
          <div>
            <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
              <span className=" cursor-pointer">{Filter_text.filter.city}</span>
            </h4>
            {listingsCities?.map((item, index, array) => {
              if (array.length > 4) {
                if (index < 4) {
                  return (
                    <p
                      className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                      key={Math.random()}
                      onClick={() => filter_by_city(item)}
                    >
                      <span className=" ">{item}</span>
                      <input
                        className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                        type="radio"
                        checked={city === item ? true : false}
                      />
                    </p>
                  );
                }
                if (index === 4) {
                  return (
                    <button
                      className=" text-xs text-primary font-semibold py-[7px]  flex justify-between items-center  cursor-pointer w-full"
                      onClick={() => setIsSeeAllCities(!isSeeAllCities)}
                    >
                      <span>
                        {Filter_text.filter.see_all}{' '}
                        {Filter_text.filter.see_all}
                      </span>
                      <span
                        className={isSeeAllCities && 'transform rotate-180'}
                      >
                        {angle_down}
                      </span>
                    </button>
                  );
                }
              } else {
                return (
                  <p
                    className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                    key={Math.random()}
                    onClick={() => filter_by_city(item)}
                  >
                    <span className=" ">{item}</span>

                    <input
                      className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                      type="radio"
                      checked={city === item ? true : false}
                    />
                  </p>
                );
              }
            })}

            {isSeeAllCities &&
              listingsCities?.map((item, index, array) => {
                if (array.length > 4) {
                  if (index > 4) {
                    return (
                      <p
                        className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                        key={Math.random()}
                        onClick={() => filter_by_city(item)}
                      >
                        <span className=" ">{item}</span>
                        {city === item && (
                          <span className="mr-2">{check_icon}</span>
                        )}
                      </p>
                    );
                  }
                }
              })}
          </div>
        )}
        {/* Sort */}
        {data?.show_sort && (
          <div className=" mt-3">
            <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
              <span className=" cursor-pointer">{Filter_text.filter.sort}</span>
            </h4>
            {priceRange?.map((item) => {
              return (
                <p
                  className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                  key={Math.random()}
                  onClick={() => filter_by_price_rang(item.value)}
                >
                  <span className=" ">{Filter_text.filter[item.value]}</span>
                  <input
                    className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                    type="radio"
                    checked={sort === item.value ? true : false}
                  />
                </p>
              );
            })}
          </div>
        )}
        {/* Atttributes  */}
        {data?.show_attributes && (
          <div className=" mt-3">
            {allAttributes?.map((item) => {
              return (
                item?.show_in_filter && (
                  <ul className="" key={Math.random()}>
                    <li
                      className="text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  cursor-pointer"
                      onClick={(e) => toggleChildren(e, item.id, item.values)}
                    >
                      <span>{item.name}</span>
                      {item?.values?.length > 0 ? (
                        <span
                          className={
                            activeParent.includes(item.id)
                              ? 'transform rotate-180'
                              : ''
                          }
                        >
                          {angle_down}
                        </span>
                      ) : (
                        <input
                          className=" form-check-input appearance-none h-3 w-3 border border-gray-400 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                          type="checkbox"
                          checked={
                            selectedAtValues?.includes(`${item.id}`)
                              ? true
                              : false
                          }
                          onChange={() => filter_by_attribute_value(item.id)}
                        />
                      )}
                    </li>
                    {item?.values?.length > 0 &&
                      item?.values?.map((vl) => {
                        return (
                          <li
                            className={
                              activeParent.includes(item.id)
                                ? 'text-[12px] pl-3 text-[#4F4F4F] font-semibold py-[7px]    flex justify-between items-center   transition duration-500  ease-in-out'
                                : 'hidden'
                            }
                            key={Math.random()}
                          >
                            <span className=" ">{vl.name}</span>
                            <input
                              className=" form-check-input appearance-none h-3 w-3 border border-gray-400 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                              type="checkbox"
                              checked={
                                selectedAtValues?.includes(`${vl.id}`)
                                  ? true
                                  : false
                              }
                              onChange={() => filter_by_attribute_value(vl.id)}
                            />
                          </li>
                        );
                      })}
                  </ul>
                )
              );
            })}
          </div>
        )}
        {/* Ratings Range */}
        {data?.show_rating && (
          <div className=" mt-3  pb-[30px]">
            <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
              <span className=" cursor-pointer">
                {Filter_text.filter.rating}
              </span>
            </h4>

            <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
              <button
                className=" cursor-pointer flex items-center"
                onClick={() => filter_by_rating(5)}
              >
                <span>{star_icon}</span>
                <span>{star_icon}</span>
                <span>{star_icon}</span>
                <span>{star_icon}</span>
                <span>{star_icon}</span>
              </button>
              <input
                className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                type="radio"
                checked={rating == 5 ? true : false}
                onClick={() => filter_by_rating(5)}
              />
            </p>
            <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
              <button
                className=" cursor-pointer flex items-center"
                onClick={() => filter_by_rating(4)}
              >
                <span>{star_icon}</span>
                <span>{star_icon}</span>
                <span>{star_icon}</span>
                <span>{star_icon}</span>
              </button>

              <input
                className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                type="radio"
                checked={rating == 4 ? true : false}
                onClick={() => filter_by_rating(4)}
              />
            </p>
            <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
              <button
                className=" cursor-pointer flex items-center"
                onClick={() => filter_by_rating(3)}
              >
                <span>{star_icon}</span>
                <span>{star_icon}</span>
                <span>{star_icon}</span>
              </button>

              <input
                className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                type="radio"
                checked={rating == 3 ? true : false}
                onClick={() => filter_by_rating(3)}
              />
            </p>
            <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
              <button
                className=" cursor-pointer flex items-center"
                onClick={() => filter_by_rating(2)}
              >
                <span>{star_icon}</span>
                <span>{star_icon}</span>
              </button>

              <input
                className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                type="radio"
                checked={rating == 2 ? true : false}
                onClick={() => filter_by_rating(2)}
              />
            </p>
            <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
              <button
                className=" cursor-pointer flex items-center"
                onClick={() => filter_by_rating(1)}
              >
                <span>{star_icon}</span>
              </button>

              <input
                className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                type="radio"
                checked={rating == 1 ? true : false}
                onClick={() => filter_by_rating(1)}
              />
            </p>
          </div>
        )}
        {/* Reset Button */}
        <button
          className=" text-xm font font-medium text-red-600 cursor-pointer pb-[30%]"
          onClick={() => {
            router.push({
              query: {
                page: router.query?.page ? router.query?.page : 1,
              },
            });
            setSelectedAtValues([]), setSelectedCategories([]);
          }}
        >
          {Filter_text.filter.clear}
        </button>
      </div>
    </div>
  );
};

export default DefaultOpenListingFilter;
