import React from 'react';
import Dropdown from '../../Shared/utils/Dropdown';
import { Menubar_text } from '../../../constant/Translation/Heading';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { categorySelector } from '../../../store/feature/categorySlice';
import { slug as SlugChecker } from 'github-slugger';
import { useRouter } from 'next/router';

const CategoriesDropDown = ({ data }) => {
  const { all_categories, all_account_categories } =
    useSelector(categorySelector);
  const router = useRouter();

  const categories =
    data?.type === 'accounts' ? all_account_categories : all_categories;

  return (
    <ul className=" inline-block">
      <Dropdown title={Menubar_text.more.categories}>
        {/* 2nd level: hover */}
        {categories?.map((item, index, array) => {
          return (
            <li>
              <Link
                href={{
                  pathname: data?.type === 'accounts' ? `/a/` : `/lc/[slug]`,
                  query: {
                    slug: item.slug
                      ? item.slug
                      : `${item?.id}-${SlugChecker(item.name)}`,
                    category_id: item.id,
                  },
                }}
              >
                <a className="font-medium text-sm text-gray-800 hover:text-primary flex py-2 px-5 leading-tight">
                  {item.name.length > 20
                    ? item.name.substring(0, 19) + '.'
                    : item.name}
                </a>
              </Link>
            </li>
          );
        })}
      </Dropdown>
    </ul>
  );
};

export default CategoriesDropDown;
