import React, { memo } from 'react';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import ReadingComposer from '../Shared/Composer/ReadingComposer';

const ComposerDescriptionBlock = ({ data, styles }) => {
  return (
    <div className="  mx-auto " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <ReadingComposer
          contents={get_data_by_current_language({
            data_list: data?.translated_data,
            key_name: 'description',
            default_data: data?.description,
          })}
          mainClass={` prose prose-sm sm:prose lg:prose-lg xl:prose-xl m-0 focus:outline-none   prose-a:text-primary  prose-a:border-0 hover:prose-a:underline prose-img:m-0`}
        />
      </div>
    </div>
  );
};

export default memo(ComposerDescriptionBlock);
