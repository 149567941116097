import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import {
  accountFilterSelector,
  getAccountCategoriesForFilter,
  onFilterParamsUpdate,
} from '../../store/feature/filter/accountFilterSlice';
import { getMainPath } from '../../utils/getMainPath';
import { replaceSpecificStyles } from '../../utils/replaceSpacificStyles';

const AccountCategoryButtonFilter = ({ filer_item, styles }) => {
  const {
    filter_type,
    title,
    category_button_image,
    translated_data,
    category_ids,
    is_show_title,
    is_depend_on_search,
    auto_apply_filters,
  } = filer_item;
  const router = useRouter();
  const dispatch = useDispatch();
  const { account_categories, filter_params, queries } = useSelector(
    accountFilterSelector
  );
  const selected_category_ids = filter_params?.category_ids ?? [];

  useEffect(() => {
    if (account_categories === null) {
      dispatch(getAccountCategoriesForFilter({}));
    }
  }, [0]);

  //
  const get_category = (id) => {
    const category = account_categories?.find((item) => item.id == id);
    return category;
  };

  // filter by category
  const filter_by_category = (id) => {
    const check = selected_category_ids?.find((ct) => ct == id);
    if (check === undefined) {
      dispatch(
        onFilterParamsUpdate({
          category_ids: [id],
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    } else {
      const filter = selected_category_ids?.filter((ct) => ct != id);
      dispatch(
        onFilterParamsUpdate({
          category_ids: filter?.length > 0 ? filter : null,
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    }
  };

  const onUpdateQuires = (updated_quires) => {
    if (is_depend_on_search !== true && auto_apply_filters !== true) {
      router.push({
        pathname: getMainPath(router.asPath),
        query: updated_quires ?? queries,
      });
    }
  };

  return (
    <div
      style={{
        display: styles?.filter_component_style?.display,
        ...get_dynamic_styles(styles?.filter_component_style),
      }}
    >
      {title && is_show_title && (
        <p style={get_dynamic_styles(styles?.filter_title_style)}>
          {get_data_by_current_language({
            data_list: translated_data,
            key_name: 'title',
            default_data: title,
          })}
        </p>
      )}

      {account_categories?.length > 0 &&
        category_ids?.map((category_id) => {
          return (
            <button
              className=" border"
              key={Math.random()}
              onClick={() => filter_by_category(category_id)}
              style={{
                display: styles?.filter_category_button_style?.display,
                ...get_dynamic_styles(
                  selected_category_ids?.includes(`${category_id}`)
                    ? styles?.filter_category_button_style
                    : replaceSpecificStyles({
                        styles: styles?.filter_category_button_style,
                        remove_keys: {
                          background: 'transparent',
                          text_color:
                            styles?.filter_category_button_style?.background,
                        },
                        initial_values: {
                          border_color:
                            styles?.filter_category_button_style?.background,
                        },
                      })
                ),
              }}
            >
              {category_button_image && (
                <img
                  style={get_dynamic_styles(
                    styles?.filter_category_button_image_style
                  )}
                  src={get_category(category_id)?.image_path}
                  alt=""
                />
              )}
              <span className=" ">{get_category(category_id)?.name}</span>
            </button>
          );
        })}
    </div>
  );
};

export default AccountCategoryButtonFilter;
