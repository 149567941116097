import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'react-image-gallery/styles/css/image-gallery.css';
// Import Swiper styles
import 'swiper/css/bundle';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const ImageSLiderBlock = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <Swiper
        slidesPerView="auto"
        slidesPerGroup={1}
        spaceBetween={16}
        loop={data?.loop ? true : false}
        navigation={false}
        autoplay={
          data?.loop
            ? {
                delay: styles?.main_styles?.delay ?? 2500,
                disableOnInteraction: false,
              }
            : false
        }
        pagination={
          data?.show_pagination
            ? {
                clickable: true,
              }
            : false
        }
      >
        {data?.images?.map((image, i) => {
          return (
            <SwiperSlide
              key={i}
              className="   "
              style={{
                width: get_dynamic_styles(styles?.image)?.width
                  ? get_dynamic_styles(styles?.image)?.width
                  : '100%',
              }}
            >
              <img
                src={image?.image_src}
                alt={image?.image_src}
                layout="fill"
                priority={true}
                style={get_dynamic_styles(styles?.image)}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ImageSLiderBlock;
