import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  clearListings,
  getAllListings,
  listingLike,
  listingSelector,
} from '../../../store/feature/listingSlice';
import ReactPaginate from 'react-paginate';
import { Listing_text } from '../../../constant/Translation/Listing';
import DynamicListingCard from '../../Shared/Cards/DynamicListingCard';

import { check_login } from '../../../constant/check_auth';
import InfiniteScroll from 'react-infinite-scroll-component';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import AccountsMapViewSkeleton from '../../Shared/skeletonUI/AccountsMapViewSkeleton';
import { Account_text } from '../../../constant/Translation/Account';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import DynamicAccounts from '../../AllAccounts/Accounts/DynamicAccounts';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import AccountListViewSkeleton from '../../Shared/skeletonUI/AccountsListViewSkeleton';
import {
  dynamicPageSectionOptimisticUpdate,
  dynamicPageSelector,
  getDynamicPageSection,
  setError,
} from '../../../store/feature/Pages/DynamicPage/dynamicPageSlice';
import { removeKeysFromObject } from '../../../utils/keyRemoveFromObject';
import {
  get_all_accounts,
  storeSelector,
} from '../../../store/feature/storeSlice';
import Pagination from '../../Shared/Pagination/Pagination';
import { accountFilterSelector } from '../../../store/feature/filter/accountFilterSlice';

const DynamicStoresListView = ({ data, styles }) => {
  const [pageCount, setPageCount] = useState(0);
  const router = useRouter();
  const dispatch = useDispatch();
  const { login, auth_key } = useSelector(authSelector);
  const account_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.account_card ?? {};
  const fetch_params = {
    ...router.query,
  };

  const {
    all_accounts,
    isAllAccountsFetching,
    all_accounts_page,
    all_accounts_total_records,
  } = useSelector(storeSelector);
  const { filter_params, queries } = useSelector(accountFilterSelector);

  useEffect(() => {
    if (data?.reload_on_params_change === true) {
      dispatch(
        get_all_accounts({
          bodyParam: {
            ...fetch_params,
            status: 2,
            type: 'accounts',
            per_page: data?.per_page ?? 30,
          },
        })
      );
    }
  }, [router]);
  useEffect(() => {
    if (data?.reload_on_params_change === false) {
      dispatch(
        get_all_accounts({
          bodyParam: {
            ...queries,
            status: 2,
            type: 'accounts',
            per_page: data?.per_page ?? 30,
          },
        })
      );
    }
  }, [queries]);

  const moreAccounts = (next_page) => {
    console.log(data);
    router.push({
      query: {
        ...fetch_params,
        page: next_page,
      },
    });
  };

  useEffect(() => {
    const totalpage = Math.ceil(all_accounts_total_records / data?.per_page);
    if (Number(all_accounts_total_records) > data?.per_page) {
      setPageCount(totalpage);
    } else {
      setPageCount(0);
    }
  }, [all_accounts_total_records]);

  // follow account
  const follow = (id, isFollow) => {
    if (check_login(router)) {
      axios
        .post('/api/a/follow_account', { id, isFollow })
        .then((res) => {
          dispatch(
            get_all_accounts({
              bodyParam: {
                page: router.query?.page ? router.query?.page : 1,
                type: 'accounts',
                per_page: data?.per_page ?? 30,
                status: 2,
              },
            })
          );
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  return (
    <>
      {all_accounts !== null ? (
        <div
          className="w-full   "
          style={get_dynamic_styles(styles?.main_styles)}
        >
          <div
            className="w-full   "
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              HomeCardBoxDefaultStyles
            )}
          >
            {all_accounts?.length > 0 ? (
              <div>
                {/* Map view Infinite scroll */}
                {data?.pagination_type === 'scroll' && (
                  <InfiniteScroll
                    dataLength={all_accounts?.length}
                    next={() => moreAccounts(Number(all_accounts?.page) + 1)}
                    hasMore={
                      all_accounts.length === all_accounts?.total_records
                        ? false
                        : true
                    }
                    loader={
                      account_card_styles?.card_type?.type !== 'list_card' ? (
                        <AccountsMapViewSkeleton
                          per_page={fetch_by?.per_page}
                        />
                      ) : (
                        <AccountListViewSkeleton
                          per_page={fetch_by?.per_page}
                        />
                      )
                    }
                  >
                    <DynamicAccounts
                      accounts={all_accounts}
                      account_follow={follow}
                    />
                  </InfiniteScroll>
                )}

                {/* pagination type  */}
                {data?.pagination_type !== 'scroll' && (
                  <>
                    <DynamicAccounts
                      accounts={all_accounts}
                      account_follow={follow}
                    />

                    <div className="mt-12   flex justify-center pb-12">
                      {all_accounts !== null && (
                        <Pagination
                          moreData={moreAccounts}
                          current_page={all_accounts_page}
                          pageCount={pageCount}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className=" w-full h-[200px] mt-5 flex justify-center items-start">
                <div
                  className="w-full    md:w-5/6 bg-yellow-500    text-white px-4 py-3 rounded-card  relative grid grid-cols-[5%,80%]"
                  role="alert"
                >
                  <div className="flex items-center justify-center w-6 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </div>
                  <div className="ml-5">
                    <span className="   md:ml-2">
                      {Account_text.storelist.no_stores_available}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={get_dynamic_styles(styles?.main_styles)}>
          <div
            className="w-full   "
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              HomeCardBoxDefaultStyles
            )}
          >
            {account_card_styles?.card_type?.type !== 'list_card' ? (
              <AccountsMapViewSkeleton per_page={fetch_by?.per_page} />
            ) : (
              <AccountListViewSkeleton per_page={fetch_by?.per_page} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DynamicStoresListView;
