export var General_text = {
  general: {
    all: 'All',
    allItemsAreSelected: 'All items are selected',
    clearSearch: 'Clear Search',
    clearSelected: 'Clear Selected',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Custom Select Placeholder...',
    create: 'Create',
    home: 'Home',

    //
    image_size_alert:
      'Image size is too large. Please select an image less than or equal to 1 MB.',
    google_translate: 'Translate by Google',
    back: 'Back',
    finish: 'Finish',
    upload_audio: 'Upload Audio file',
    upload_video: 'Upload Video file',

    yes: 'Yes',
    not_found_message: 'The page you requested could not be found.',
    subscribe_btn_title: 'Subscribe',
    subscribe_title: 'Upgrade now!',
    subscribe_description: 'Subscribe a package to post more listings',
    skip_cropping: 'Skip Cropping',
    crop: 'Crop',
    copy: 'Copy',
    save: 'Save',
    continue: 'Continue',
    select: 'Select',
    add: 'Add',
    edit: 'Edit',
    not_found: ' Not found',
    back_to_home: 'Back to home',
    required: 'required',
    contact_us: 'Contact us',
    post: 'post',
    submit: 'Submit',

    alert_message_app_permission_gallery:
      'App needs permission to access external storage',
    alert_message_app_permission_location:
      'App need permission to access location',
    alert_message_camera_permission:
      'App need camera permission to capture image',
    app_name: 'Albill',
    app_update:
      'Our app just got better. Update the app now to enjoy the latest improvements and features.',
    cancel: 'Cancel',
    close: 'Close',
    deleting: 'Deleting',
    done: 'Done',
    get_started_slide_one: 'Empowering Artisans, Farmers & Micro Business',
    get_started_slide_three: 'Trade, Donate, Recycle & Support',
    get_started_slide_two:
      'Connecting NGOs, Social Enterprises with Communities',
    loading: 'Loading',
    off: '{value}% off',
    loading_delay_message: 'Uh-Oh! Slow or no internet connection',
    no_network:
      'Your internet connection appears to be down. Please turn it on and try again.',
    no_internet: 'No Internet',
  },
};
