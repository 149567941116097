import React from 'react';
import Breadcrumb2 from '../../Shared/Breadcrumb2';
import ReportButton from '../report/ReportButton';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { slug as SlugChecker } from 'github-slugger';
import { General_text } from '../../../constant/Translation/General';
import { Menubar_text } from '../../../constant/Translation/Heading';

const OldBreadcrumb = ({ styles, data, order_number, listing_details }) => {
  return (
    <div
      className="flex items-center justify-between flex-wrap "
      style={get_dynamic_styles(styles?.main_styles)}
      key={order_number}
    >
      <Breadcrumb2
        styles={styles}
        lists={[
          { name: General_text.general.home, link: '/' },
          { name: Menubar_text.more.categories, link: '/lc' },
          {
            name: listing_details?.categories[0]?.name,
            isHide: listing_details?.categories[0]?.name ? false : true,
            link: `/lc/${
              listing_details?.categories[0]?.slug
                ? listing_details?.categories[0]?.slug
                : `${listing_details?.categories[0]?.id}-${SlugChecker(
                    listing_details?.categories[0]?.name
                  )}`
            }`,
          },
          {
            name: listing_details?.title,
            link: '',
          },
        ]}
      />
      <ReportButton listing_details={listing_details} styles={styles} />
    </div>
  );
};

export default OldBreadcrumb;
