import { Listing_text } from '../constant/Translation/Listing';

export const ConvertDayTranslation = (day: string) => {
  switch (day) {
    case 'Mon':
      return Listing_text.producttime.mon;
    case 'Tue':
      return Listing_text.producttime.tue;
    case 'Wed':
      return Listing_text.producttime.wed;
    case 'Thu':
      return Listing_text.producttime.thu;
    case 'Fri':
      return Listing_text.producttime.fri;
    case 'Sat':
      return Listing_text.producttime.sat;
    case 'Sun':
      return Listing_text.producttime.sun;
    default:
      return day;
  }
};
