import React from 'react';
import { useSelector } from 'react-redux';
import { categorySelector } from '../../store/feature/categorySlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const CategoryDetails = ({ styles }) => {
  // Listings and others data from store
  const { category_details } = useSelector(categorySelector);
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div className="flex flex-col gap-2 ">
        {category_details?.name && (
          <h1 className="title-h1    ">{category_details?.name}</h1>
        )}
        {category_details?.description && (
          <p className="description-paragraph  ">
            {category_details?.description}
          </p>
        )}
      </div>
    </div>
  );
};

export default CategoryDetails;
