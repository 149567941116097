export var Menubar_text = {
  more: {
    all_listing_cities: 'All Listing Cities',
    //
    search: 'Search',
    search_city: 'Search City',
    regional_setting: 'Regional Settings',
    wallet: 'Wallet',
    profile: 'Profile',
    social_feed: 'Social Feed',
    add_new_listing: 'Add Listing',
    all_listings: 'All Listings',
    all_accounts: 'All Accounts',
    latest_listings: 'Newest',
    categories: 'Categories',
    more: 'More',
    follow_us_title: 'Follow Us On Social Media',
    categories: 'Categories',
    see_all_categories: 'See All',
    links: 'Links',
    all_rights_reserve: 'All rights reserved.',
    sitemap: 'Sitemap',
    edit_account: 'Edit Account',
    create_account: 'Create Account',
    how_it_work: 'How It Works',
    header_sell_btn_title: 'Sell',
    my_address: 'My Address',
    about: 'About',
    are_you_sure_change_tenant_id: 'Are you sure? Do you want to change tenant',
    exit: 'Exit',
    exit_from: 'Exit from <Tenant name>',
    faq: 'FAQ',
    feedback: 'Feedback',
    language: 'Language',
    logging_out: 'Logging out',
    my_cart: 'My Cart',
    my_groups: 'My Groups',
    my_order: 'My Order',
    my_orders: 'My Orders',
    my_sales: 'My Sales',
    my_store: 'My Account',
    my_wish_list: 'My WishList',
    payments: 'Payments',
    phone_number: 'Phone Number',
    privacy_policy: 'Privacy Policy',
    purchases: 'Purchases',
    settings: 'Settings',
    signin_signup: 'Sign in/Sign up',
    support: 'Support',
    tell_a_friend: 'Tell a Friend',
    tenant_exit_confirm_message: 'This will sign out the session on',
    tenant_exit_confirm_title: 'Are you sure?',
    terms_condition: 'Terms & Conditions',
    welcome: 'Welcome!',
    more_stored_i_blocked: 'Accounts I’ve blocked',
    more_stores_i_follow: 'Accounts I follow',
    my_store_orders: 'My Account Orders',
    rate_app: 'Rate the app',
    change_tenant: 'Change Tenant',
    customer: 'Customer',
    business: 'Business',
  },
};
