import React, { useEffect, useState } from 'react';
import {
  categoryListings,
  categorySelector,
} from '../../store/feature/categorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { listingLike } from '../../store/feature/listingSlice';
import ListingsList from '../Listings/ListingsList';
import Breadcrumb from '../Shared/Breadcrumb';
import { General_text } from '../../constant/Translation/General';
import { Menubar_text } from '../../constant/Translation/Heading';
import Pagination from '../Shared/Pagination/Pagination';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { Listing_text } from '../../constant/Translation/Listing';

const CategoryListings = ({ data, styles }) => {
  const [pageCount, setPageCount] = useState(0);

  const router = useRouter();
  const dispatch = useDispatch();

  // Pagination
  const moreListings = (next_page) => {
    router.push({
      query: { ...router.query, page: Number(next_page) },
    });
  };

  // Listings and others data from store
  const { category_listings, page, total_records, category_details } =
    useSelector(categorySelector);

  useEffect(() => {
    if (category_details?.id) {
      dispatch(
        categoryListings({
          prams: { category_id: category_details?.id, ...router.query },
        })
      );
    }
  }, [category_details]);

  useEffect(() => {
    const totalpage = Math.ceil(total_records / 30);
    if (Number(total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [total_records]);

  //reset_filter
  const reset_filter = () => {
    router.push({
      query: {
        slug: router.query.slug,
        page: router.query?.page ? router.query?.page : 1,
      },
    });
  };

  //like
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id,
          isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            categoryListings({
              prams: { category_id: category_details?.id, ...router.query },
            })
          );
        }
      });
    }
  };

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {/* products */}
      {category_listings === null || category_listings?.length > 0 ? (
        <div>
          <ListingsList Products={category_listings} like_listing={like} />
        </div>
      ) : (
        <div className=" w-full h-[200px] mt-5 flex justify-center items-start">
          <div
            className="w-full    md:w-5/6 bg-yellow-500    text-white px-4 py-3 rounded-card  relative grid grid-cols-[5%,80%]"
            role="alert"
          >
            <div className="flex items-center justify-center w-6 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </div>
            <div className="ml-5">
              <span className="   md:ml-2">
                {Listing_text.productlist.no_products_found}
              </span>
            </div>
          </div>
        </div>
      )}

      {/*  */}
      {category_listings?.length > 0 && (
        <div className="my-2">
          <Breadcrumb
            lists={[
              { name: General_text.general.home, link: '/' },
              { name: Menubar_text.more.categories, link: '/lc' },
              {
                name: category_details?.name ?? '',
                link: ``,
              },
            ]}
          />
        </div>
      )}
      {/*  */}
      {category_listings?.length > 0 && (
        <div className="mt-12 pb-12 flex justify-center ">
          <Pagination
            current_page={page}
            pageCount={pageCount}
            moreData={moreListings}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryListings;
