import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../constant/Web_constant';

export const checkRules = (rules) => {
  const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);

  if (rules?.is_show_if_user_is_logged_in == true && isLoggedIN) {
    return true;
  } else if (rules?.is_show_if_user_is_not_logged_in == true && !isLoggedIN) {
    return true;
  } else {
    return false;
  }
};
